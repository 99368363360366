import React, {useState, useEffect, useCallback} from 'react'
import {NavLink, withRouter} from 'react-router-dom'

import './styles.scss';

const Navbar = ({location}) => {
    const [shouldTransition, setShouldTransition] = useState(false)
    const [underlineStyling, setUnderlineStyling] = useState({opacity: 0, width: 0, top: 100});
    const [currIndex, setCurrIndex] = useState(location.pathname.split('/').pop());
    const [isToggled, setIsToggled] = useState(false);
    const IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const toggleMenu = () => {
        setIsToggled(!isToggled);
    }

    const [isPT, setPT] = useState(location.search === '')
    const [isENG, setENG] = useState(location.search.indexOf('language=en') > -1)
    const [isESP, setESP] = useState(location.search.indexOf('language=es') > -1)

    let menuItems = [];
    let buttonTravel = 'CONHEÇA O SÍTIO E A HÍPICA';
    if (location.search === '') {
        buttonTravel = 'CONHEÇA O SÍTIO E A HÍPICA'
        menuItems = [
            {
                key: 'historia',
                label: 'História'
            }, {
                key: 'empreendedorismo',
                label: 'Empreendedorismo'
            }, {
                key: 'hipismo',
                label: 'Hipismo'
            }, {
                label: 'Coleção de arte',
                key: 'colecao'
            }, {
                label: 'Meio Ambiente',
                key: 'meio-ambiente'
            },
            {
                label: 'Repcom',
                key: 'repcom'
            }
        ]
    } else if (location.search.indexOf('language=en') > -1) {
        buttonTravel = 'KNOW THE SITE AND HYPIC'
        menuItems = [
            {
                key: 'historia',
                label: 'History'
            }, {
                key: 'empreendedorismo',
                label: 'Entrepreneurship'
            }, {
                key: 'hipismo',
                label: 'Equestrianism'
            }, {
                label: 'Art collection',
                key: 'colecao'
            }, {
                label: 'Environment',
                key: 'meio-ambiente'
            }
        ]
    } else if(location.search.indexOf('language=es') > -1) {
        buttonTravel = 'CONOZCA EL SITIO E HYPIC'
        menuItems = [
            {
                key: 'historia',
                label: 'Historia'
            }, {
                key: 'empreendedorismo',
                label: 'Emprendimiento'
            }, {
                key: 'hipismo',
                label: 'Ecuestre'
            }, {
                label: 'Colección de arte',
                key: 'colecao'
            }, {
                label: 'Medio ambiente',
                key: 'meio-ambiente'
            }
        ]
    }

    const moveMenu = useCallback((index, toggle) => {
        const item = document.querySelector(`.Navbar__item[data-key="${index}"]`);
        setCurrIndex(index);

        if(item){
            if (!toggle) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                toggleMenu();
            }
            
            setTimeout(() => {
                // Animações do traço underline: Se for p/ o primeiro, esconder
                setUnderlineStyling({
                    opacity: 1,
                    left: item.offsetLeft,
                    top: item.offsetTop + 17,
                    width: item.offsetWidth
                })
            }, 100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        moveMenu(currIndex, true);

        setTimeout(() => setShouldTransition(true), 400)
    }, [currIndex, moveMenu]);

    function changeLanguage(lang) {
        moveMenu(currIndex, true);
        if (lang === 'pt') {
            setPT(true)
            setENG(false)
            setESP(false)
        } else if (lang === 'en') {
            setPT(false)
            setENG(true)
            setESP(false)
        } else if (lang === 'es') {
            setPT(false)
            setENG(false)
            setESP(true)
        }
    }

    return (
        <React.Fragment>
            <div
                className={`Navbar__toggler ${isToggled
                ? 'toggled'
                : ''}`}>
                <button
                    className={`${isToggled
                    ? 'active'
                    : ''} ${IOS ? 'ios' : ''}`}
                    onClick={() => toggleMenu()}>
                    <div></div>
                    <div></div>
                    <div></div>
                </button>

                <NavLink className="Navbar__toggler-home" to="/">
                    Chiquinho <span>Brandão</span>
                </NavLink>
            </div>
            <nav
                className={`Navbar ${isToggled
                ? 'toggled'
                : ''}`}>
                <div className="container">
                    <div
                        className={`Navbar__content ${isToggled
                        ? 'toggled'
                        : ''} ${IOS
                            ? 'ios'
                            : ''}`}>

                        <div className="Navbar__brand">
                            <NavLink to="/">
                                Chiquinho Brandão
                            </NavLink>
                        </div>
                        <div className="Navbar__links">
                            <hr
                                className="Navbar__underline"
                                style={underlineStyling}
                                data-shouldTransition={shouldTransition}/> {menuItems.map((i, index) => (
                                <NavLink
                                    data-key={i.key}
                                    className={`Navbar__item`}
                                    key={i.key}
                                    to={i.key + location.search}
                                    onClick={(e) => moveMenu(i.key)}>
                                    {i.label}
                                </NavLink>
                            ))}
                            <div class="goToHipica">
                                <a href="https://santoantonio.chiquinhobrandao.com.br/">
                                    {buttonTravel}
                                </a>
                            </div>
                            {/* <div class="translateData">
                                <NavLink 
                                    className={`button ${isPT ? 'translated' : ''}`} 
                                    onClick={e => changeLanguage('pt')}
                                    to={`${location.pathname}`}
                                >
                                    PT
                                </NavLink>
                                <NavLink 
                                    className={`button ${isENG ? 'translated' : ''}`} 
                                    onClick={e => changeLanguage('en')}
                                    to={`${location.pathname}?language=en`}
                                >
                                    ENG
                                </NavLink>
                                <NavLink 
                                    className={`button ${isESP ? 'translated' : ''}`} 
                                    onClick={e => changeLanguage('es')}
                                    to={`${location.pathname}?language=es`}
                                >
                                    ESP
                                </NavLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default withRouter(Navbar);