import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import PageTransition from 'react-router-page-transition'
// import {Helmet} from "react-helmet"

// Import Containers (Pages)
import Colecao from 'containers/Colecao'
import Empreendedorismo from 'containers/Empreendedorismo'
import Error404 from 'containers/Error/404'
import Hipismo from 'containers/Hipismo'
import Historia from 'containers/Historia'
import Home from 'containers/Home'
import MeioAmbiente from 'containers/MeioAmbiente'
import Repcom from 'containers/Repcom'

const AppRouter = ({location}) => {
    const routes = [
        {
            path: '',
            name: 'Home',
            Component: Home,
            exact: true
        }, 
        {
            path: '/historia',
            name: 'História',
            Component: Historia,
            exact: true
        }, 
        {
            path: '/empreendedorismo',
            name: 'Empreendedorismo',
            Component: Empreendedorismo,
            exact: true
        }, 
        {
            path: '/hipismo',
            name: 'Hipismo',
            Component: Hipismo,
            exact: true
        }, 
        {
            path: '/colecao',
            name: 'Coleção de Arte',
            Component: Colecao,
            exact: true
        }, 
        {
            path: '/meio-ambiente',
            name: 'Meio Ambiente',
            Component: MeioAmbiente,
            exact: true
        },
        {
            path: '/repcom',
            name: 'REPCOM',
            Component: Repcom,
            exact: true
        },
        {
            path: '/',
            name: 'Erro 404',
            Component: Error404,
            exact: false
        }
    ];
    
    return (
        <PageTransition timeout={800}>
            <Switch location={location}>
                <PageTransition timeout={800}>
                    <Switch location={location}>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/historia" component={Historia} />
                        <Route exact path="/empreendedorismo" component={Empreendedorismo} />
                        <Route exact path="/hipismo" component={Hipismo} />
                        <Route exact path="/colecao" component={Colecao} />
                        <Route exact path="/meio-ambiente" component={MeioAmbiente} />
                        <Route exact path="/repcom" component={Repcom} />
                        <Route component={Error404} />
                    </Switch>
                </PageTransition>
            </Switch>
        </PageTransition>
    )
}

export default withRouter(AppRouter);
