// import axios from 'axios.instance'
import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import { useLocation } from 'react-router-dom'

import PageBanner from 'components/Sections/PageBanner'
import './styles.scss'

const Repcom = (props) => {

    // eslint-disable-next-line
    const [isFetching, setIsFetching] = useState(true);
    const [data, setData] = useState(false);
    const location = useLocation()

    let state = {
        titulo: 'Repcom'
    }

    let language = 'P';;

    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    return (
        <section className="Repcom page-interna pb-5 mb-2 mb-lg-5">
            {data ?
                <Helmet>
                    {/* <meta name="keywords" content={data.keywords} /> */}
                    <meta name="description" content={'Nos dias 30 e 31 de outubro de 2024, a FSB Holding viveu um momento histórico com a realização do REPCOM, o maior evento sobre reputação da América Latina. Mais do que uma maneira de celebrar os avanços da comunicação empresarial, aqueles dois dias reafirmaram um propósito que acompanha Francisco Soares Brandão desde o início de sua trajetória. Como já mencionado por aqui, o empresário estabeleceu, em 1980, a meta de transformar a FSB na maior plataforma de comunicação corporativa do Brasil, destacando-se como o mais relevante ecossistema de empresas do segmento no país.'} />
                    <title>{'Repcom'}</title>
                </Helmet>
                : null}

            <PageBanner title={'Repcom'} background={'/bg-repcom.jpg'} />

            <main class="pt-3 pb-5 pt-lg-5 my-3 my-lg-5">
                <div className="container pt-4 pt-lg-5">
                    <div className="row">

                        <div className="container-interna markup">
                            <h3>REPCOM: FSB segue construindo a sua história </h3>
                            <p><i>Encontro reuniu líderes e formadores de opinião para abordar o futuro da comunicação e o valor da reputação para os negócios</i></p>
                            <p>
                                Nos dias 30 e 31 de outubro de 2024, a FSB Holding viveu um momento histórico com a realização do REPCOM, o maior evento sobre reputação da América Latina. Mais do que uma maneira de celebrar os avanços da comunicação empresarial, aqueles dois dias reafirmaram um propósito que acompanha Francisco Soares Brandão desde o início de sua trajetória.
                            </p>
                            <p>
                                Como já mencionado por aqui, o empresário estabeleceu, em 1980, a meta de transformar a FSB na maior plataforma de comunicação corporativa do Brasil, destacando-se como o mais relevante ecossistema de empresas do segmento no país.
                            </p>
                            <figure>
                                <div className="img-brush">
                                    <img src={'/bg-repcom.jpg'} alt={'repcom'} />
                                </div>
                                <figcaption>
                                    Sócios e o time da FSB: Repcom fez sucesso já em sua primeira edição, realizada em SP
                                </figcaption>
                            </figure>
                            <p>
                                Nesse contexto, o REPCOM foi mais uma prova de que a visão de Chiquinho Brandão se concretiza a cada novo passo da FSB. Do planejamento à execução, o evento – realizado em São Paulo - destacou o compromisso da companhia com a excelência e sinalizou mais uma vez o futuro promissor que define a trajetória do negócio.
                            </p>
                            <figure>
                                <div className="img-brush">
                                    <img src={'/img-repcom-1.jpg'} alt={'asdsadsadsad'} />
                                </div>
                                <figcaption>
                                    O sócio-fundador, Francisco Brandão (D), com o CEO e sócio Marcos Trindade
                                </figcaption>
                            </figure>
                            <p>
                                O jantar, em 30/10, e as discussões nos painéis do dia seguinte - que reuniram mais de 800 pessoas no hotel Grand Hyatt (SP) - destacaram a reputação como um dos principais ativos para pessoas, marcas e empresas. O sucesso do evento já em sua primeira edição foi resultado do trabalho dedicado de uma grande equipe, sob a liderança do CEO da holding, Marcos Trindade, que desempenhou um papel fundamental em cada etapa do processo.
                            </p>
                            <p>
                                Como parte da celebração, foi compartilhado um vídeo na abertura do evento, que traduz de forma clara o DNA do negócio.
                            </p>
                            <figure>
                                <iframe style={{'border' : 0, borderRadius: '10px'}} src="https://www.youtube.com/embed/KCPHD84VRbc" width={"100%"} height={400}>
                                </iframe>
                            </figure>
                            <p>
                                Em linhas gerais, o REPCOM marcou o início de um novo capítulo na história da FSB. Guiado pelo trabalho conjunto e um propósito bem definido, o ecossistema segue inovando e fortalecendo seu papel como protagonista na comunicação corporativa do Brasil.
                            </p>
                        </div>

                    </div>
                </div>
            </main>
        </section>
    )
}

export default Repcom
